import { Grid } from "@material-ui/core";
import React from "react";
import styles from "./styles";
export const Column = ({ children, title = "" }) => {
  const classes = styles();
  return (
    <Grid item xs={6} sm={2}>
      <Grid container direction="column" spacing={0}>
        <Grid item xs={12}>
          <h2 className={classes.h2}>{title}</h2>
        </Grid>
        {children}
      </Grid>
    </Grid>
  );
};
export default Column;
