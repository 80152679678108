import React from "react";
import { Typography, Grid } from "@material-ui/core";

export const Row = ({ name = "", userInfo = {} }) => {
  return (
    <Grid item>
      <Typography variant="caption">{userInfo[name]}</Typography>
    </Grid>
  );
};
export default Row;
