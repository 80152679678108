import { makeStyles } from "@material-ui/core";
const styles = makeStyles((theme) => ({
  table: {
    minWidth: "100vw",
  },
  select: { marginLeft: "auto" },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
export default styles;
