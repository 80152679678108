import LooksList from "src/components/LooksList";
import React, { useContext, useState, useEffect, useMemo } from "react";
import { Context } from "src/components/Context";

import isEmpty from "lodash/isEmpty";
import {
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  Switch,
  Box,
} from "@material-ui/core";
import Analytics from "@aws-amplify/analytics";

export const Home = () => {
  const {
    looksData,
    selections,
    brandsData,
    selectedBrand,
    setSelectedBrand,
  } = useContext(Context);
  const [data, setData] = useState(looksData);

  const [isSelection, setIsSelection] = useState(false);

  const radioButtons = useMemo(
    () =>
      brandsData.map(({ data }) => {
        const brand = data.Name;
        return (
          <FormControlLabel
            key={brand}
            value={brand}
            control={<Radio color="primary" />}
            label={brand}
            labelPlacement="end"
          />
        );
      }),
    [brandsData]
  );

  useEffect(() => {
    const filteredByBrand = looksData.filter(
      ({ data }) => data.BrandName[0] === selectedBrand
    );
    if (!isSelection) {
      setData(isEmpty(filteredByBrand) ? [] : filteredByBrand);
    } else {
      const filteredBySKU = filteredByBrand.filter(({ data }) =>
        selections.some((selection) =>
          data.SKU.length > 1
            ? data.SKU[1].data.SKU === selection.SKU ||
              data.SKU[0].data.SKU === selection.SKU
            : data.SKU[0].data.SKU === selection.SKU
        )
      );

      setData(isEmpty(filteredBySKU) ? [] : filteredBySKU);
    }
  }, [looksData, selectedBrand, isSelection, selections]);

  const handleSwitch = () => {
    setIsSelection(!isSelection);
    Analytics.record("toggle-selection");
  };

  return (
    <>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="brands"
          name="brands"
          onChange={(e) => setSelectedBrand(e.target.value)}
          value={selectedBrand || ""}
        >
          {radioButtons}
        </RadioGroup>
      </FormControl>
      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={isSelection}
              onChange={handleSwitch}
              name="isSelected"
              color="primary"
            />
          }
          label="Show selection"
        />
      </Box>
      <LooksList data={data}></LooksList>
    </>
  );
};
export default Home;
