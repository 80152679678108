import React, { useContext, useState, useMemo } from "react";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import { Typography, InputBase, TableCell, TableRow } from "@material-ui/core";
import { Context } from "../Context";
import styles from "./styles";
import Select from "../Select";
import { useFormik } from "formik";
import { API, graphqlOperation } from "aws-amplify";
import { updateProduct, deleteProduct } from "../../graphql/myMutations";
import { countPrice, numOr0 } from "src/utils/helpers";
import { useSnackbar } from "notistack";
import Img from "gatsby-image";
import Analytics from "@aws-amplify/analytics";

export const ProductRow = ({ product = {}, isCheckout }) => {
  const {
    skuData,
    products,
    fetchProducts,
    setProducts,
    checkout,
  } = useContext(Context);

  const { enqueueSnackbar } = useSnackbar();

  const SKU = product.SKU;

  let productInAirtable = skuData.find(({ data }) => data.SKU === SKU);
  productInAirtable = productInAirtable.data;

  const photo = productInAirtable.SKU_Photos
    ? productInAirtable.SKU_Photos.localFiles[0].childImageSharp.fluid
    : "";
  const colors = productInAirtable.Colors;

  const color = product.color;
  const items = product.items;
  const price = product.price;
  const WHP = product.WHP;
  const name = product.name;
  const id = product.id;
  const composition = product.composition;
  const checkoutID = checkout.id;
  const classes = styles();

  const [isEditable, setIsEditable] = useState(false);

  const parsedItems = JSON.parse(items);
  const sizes = parsedItems.map((item) =>
    Object.keys(item).map((size) => (
      <Typography display="block" key={size} variant="caption">
        {size}
      </Typography>
    ))
  );
  const onEditStart = () => {
    setIsEditable(true);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      color: color,
      items: parsedItems,
      SKU: SKU,
      price: parseInt(price),
      WHP: parseInt(WHP),
      name: name,
      id: id,
      checkoutId: checkoutID,
      composition: composition,
    },
    onSubmit: async (values) => {
      values.price = countPrice(values.items, values.WHP);
      const jsonItems = JSON.stringify(values.items);
      values.items = jsonItems;
      console.log(values);
      try {
        await API.graphql(
          graphqlOperation(updateProduct, {
            input: values,
          })
        );
        enqueueSnackbar("Items have been updated");
      } catch (err) {
        console.log(err);
      }
      setIsEditable(false);
      fetchProducts();
    },
  });
  const handleDelete = () => {
    try {
      API.graphql(
        graphqlOperation(deleteProduct, {
          input: { id: id },
        })
      );
      enqueueSnackbar("Items have been deleted");
      Analytics.record("remove-from-cart");
    } catch (err) {
      console.log(err);
    }

    const filteredProducts = products.filter((product) => product.id !== id);
    setProducts(filteredProducts);
  };
  const quantities = parsedItems.map((item, i) =>
    Object.keys(item).map((key) => {
      const size = key;

      return (
        <InputBase
          key={size}
          value={formik.values.items[i][size] || ""}
          className={classes.input}
          onChange={formik.handleChange}
          name={`items[${i}][${size}]`}
          type="number"
          placeholder="0"
          inputProps={{ className: classes.inputProps }}
          disabled={!isEditable}
        />
      );
    })
  );

  const colorSelect = useMemo(
    () => (
      <Select
        onChange={formik.handleChange}
        value={formik.values.color}
        name="color"
        options={colors}
        helperText={formik.errors.color}
        className={classes.select}
        disabled={!isEditable}
        inputProps={{ className: classes.select }}
      />
    ),
    [
      classes.select,
      colors,
      formik.errors.color,
      formik.handleChange,
      formik.values.color,
      isEditable,
    ]
  );

  const quantityArray = parsedItems.map((item) =>
    Object.values(item).reduce((acc, value) => numOr0(acc) + numOr0(value), 0)
  );
  const totalAmount = quantityArray.reduce(
    (acc, value) => numOr0(acc) + numOr0(value),
    0
  );

  return (
    <TableRow className={classes.row} key={id}>
      <TableCell>
        <Typography variant="caption">{SKU}</Typography>
      </TableCell>

      <TableCell>
        <Img className={classes.image} fluid={photo} alt="clothes" />
      </TableCell>

      <TableCell>
        <Typography variant="caption">{name}</Typography>
      </TableCell>

      <TableCell>{colorSelect}</TableCell>
      <TableCell>
        <Typography variant="caption">{composition}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant="caption">{sizes} </Typography>
      </TableCell>
      <TableCell>{quantities}</TableCell>

      <TableCell>
        <Typography variant="caption">{WHP} €</Typography>
      </TableCell>

      <TableCell>
        <Typography variant="caption">{price} €</Typography>{" "}
      </TableCell>

      <TableCell>
        <Typography variant="caption">{totalAmount}</Typography>{" "}
      </TableCell>

      {isCheckout && (
        <>
          <TableCell>
            {isEditable ? (
              <SaveIcon onClick={formik.handleSubmit} />
            ) : (
              <EditIcon onClick={onEditStart} />
            )}
          </TableCell>
          <TableCell>
            <DeleteIcon onClick={handleDelete} />
          </TableCell>
        </>
      )}
    </TableRow>
  );
};
export default ProductRow;
