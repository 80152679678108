import React from "react";
import { Grid } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import Card from "./Card";
import styles from "./styles";

export default function LooksList({ data }) {
  const classes = styles();

  const mappedCards = data.map(({ data }, id) => {
    const name = data.Name;
    const photo = data.Photo
      ? data.Photo.localFiles[0].childImageSharp.fluid
      : "";
    const SKUS = data.SKU;

    const brandName = isEmpty(data.BrandName) ? "" : data.BrandName[0];

    return (
      <Card
        key={id}
        photo={photo}
        SKUS={SKUS}
        brandName={brandName}
        name={name}
      />
    );
  });

  return (
    <Grid container spacing={10} wrap="wrap" className={classes.row}>
      {mappedCards}
    </Grid>
  );
}
