import React, { useEffect, useMemo, useContext } from "react";
import { Context } from "src/components/Context";
import Cell from "./Cell";
import styles from "../../styles";
import { navigate } from "gatsby";
import kebabCase from "lodash/kebabCase";

import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  Typography,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { countQuantity } from "src/utils/helpers";
export const OrdersHistory = () => {
  const { orders, fetchOrders, unconfirmedOrders } = useContext(Context);

  const classes = styles();
  const HEADERS = [
    "REF. #",
    "DATE",
    "BRAND",
    "P.O. #",
    "QTY",
    "AMOUNT",
    "STATUS",
  ];
  const mappedHeaders = useMemo(
    () =>
      HEADERS.map((name) => (
        <TableCell key={name} className={classes.tableCell}>
          <Typography className={classes.h2}>{name}</Typography>
        </TableCell>
      )),
    [HEADERS, classes.tableCell, classes.h2]
  );

  const countPrice = (items) =>
    items.reduce((acc, item) => parseInt(acc) + parseInt(item.price), 0);

  const rows = useMemo(
    () =>
      orders.map((order, index) => {
        const id = order.id;

        const price = order.totalPrice;

        const quantity = order.totalQuantity;

        return (
          <TableRow onClick={() => navigate(`/order/${id}`)} key={id}>
            <Cell content={index + 1} />
            <Cell content={order.updatedAt.slice(0, 10)} />
            <Cell content={order.brand} />
            <Cell content={id} />
            <Cell content={quantity} />
            <Cell content={`${price} €`} />
            <Cell content={order.status} />
          </TableRow>
        );
      }),
    [orders]
  );

  const unconfirmedRows = useMemo(
    () =>
      unconfirmedOrders.map((productsArray, index) => {
        const currentIndex = orders.length + index + 1;
        const brand = productsArray[0].brand;
        const path = kebabCase(brand);
        const price = countPrice(productsArray);
        const parsedItems = productsArray.map(
          (item) => JSON.parse(item.items),
          [orders]
        );
        const quantity = countQuantity(parsedItems);

        return (
          <TableRow onClick={() => navigate(`/order/${path}`)} key={brand}>
            <Cell content={currentIndex} />
            <Cell content="n/a" />
            <Cell content={brand} />
            <Cell content="n/a" />
            <Cell content={quantity} />
            <Cell content={`${price} €`} />
            <Cell content="Unconfirmed" />
          </TableRow>
        );
      }),
    [unconfirmedOrders, orders]
  );

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  return (
    <>
      <h1 className={classes.h1}>ORDERS HISTORY</h1>
      <TableContainer className={classes.table}>
        <Table aria-label="orders table">
          <TableHead>
            <TableRow>{mappedHeaders}</TableRow>
          </TableHead>

          <TableBody>
            {rows}
            {unconfirmedRows}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default OrdersHistory;
