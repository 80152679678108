import React from "react";
import { Formik, Form } from "formik";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import { Auth } from "aws-amplify";
import InputField from "src/components/InputField";
import FormColumn from "src/components/FormColumn";
import { AccountSchema } from "src/utils/validation";
import styles from "./styles";

import omit from "lodash/omit";

export const AccountForm = ({
  userInfo,
  isEditable,
  setIsEditable,
  setUser,
}) => {
  const classes = styles();

  return (
    <Formik
      initialValues={
        userInfo || {
          "custom:billingCompanyName": "",
          "custom:billingAddress": "",
          "custom:billingCity": "",
          "custom:billingZipCode": "",
          "custom:billingCountry": "",
          "custom:billingContact": "",
          "custom:billingEmail": "",
          "custom:billingPhone": "",
          "custom:billingTaxID": "",

          "custom:companyName": "",
          "custom:address": "",
          "custom:city": "",
          "custom:zipCode": "",
          "custom:country": "",
          "custom:contact": "",
          "custom:storeEmail": "",
          "custom:storePhone": "",
          "custom:taxID": "",
        }
      }
      validationSchema={AccountSchema}
      onSubmit={async (values) => {
        if (userInfo) {
          setIsEditable(!isEditable);

          setUser(values);
        }
        try {
          const user = await Auth.currentAuthenticatedUser();
          const authorizedValues = omit(values, [
            "email_verified",
            "sub",
            "username",
            "email",
            "name",
            "phone",
          ]);
          console.log(authorizedValues);
          await Auth.updateUserAttributes(
            user,
            omit(values, [
              "email_verified",
              "sub",
              "username",
              "email",
              "name",

              "phone",
            ])
          );
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({ submitForm, isSubmitting }, values) => (
        <Form>
          <h1 className={classes.h1}>REGISTRATION</h1>
          <Grid container direction="row" wrap="nowrap" spacing={10}>
            <FormColumn title="BILLING DETAILS">
              <InputField
                label="Company name"
                name="custom:billingCompanyName"
              />
              <InputField label="Address" name="custom:billingAddress" />
              <InputField label="City" name="custom:billingCity" />
              <InputField label="Zip code" name="custom:billingZipCode" />
              <InputField label="Country" name="custom:billingCountry" />
              <InputField label="Contact" name="custom:billingContact" />
              <InputField label="Email" name="custom:billingEmail" />
              <InputField label="Phone" name="custom:billingPhone" />
              <InputField label="Tax id" name="custom:billingTaxID" />
            </FormColumn>

            <FormColumn title="DELIVERY DETAILS">
              <InputField label="Company name" name="custom:companyName" />
              <InputField label="Address" name="custom:address" />
              <InputField label="City" name="custom:city" />
              <InputField label="Zip code" name="custom:zipCode" />
              <InputField label="Country" name="custom:country" />
              <InputField label="Contact" name="custom:contact" />
              <InputField label="Email" name="custom:storeEmail" />
              <InputField label="Phone" name="custom:storePhone" />
              <InputField label="Tax id" name="custom:taxID" />
            </FormColumn>
          </Grid>
          <Box m="5rem 0 15.625rem 0">
            <Button
              onClick={submitForm}
              className={classes.button}
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </Button>
            <Typography variant="caption">
              We will automatically receive an order and send you an OC
            </Typography>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
export default AccountForm;
