import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import styles from "./styles";
import Typography from "@material-ui/core/Typography";

export const TableHeader = ({ isCheckout }) => {
  const classes = styles();
  const columnsNames = ["SKU CODE", "ITEM", "NAME", "WHP", "ADD"];
  const columnsHeaders = columnsNames.map((name) => (
    <TableCell key={name}>
      <Typography className={classes.h2}>{name}</Typography>
    </TableCell>
  ));
  return (
    <TableHead>
      <TableRow>{columnsHeaders}</TableRow>
    </TableHead>
  );
};
export default TableHeader;
