import { makeStyles } from "@material-ui/core";
const styles = makeStyles((theme) => ({
  media: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },

  icon: {
    fontSize: "inherit",
    verticalAlign: "middle",
    paddingBottom: "3px",
  },
}));
export default styles;
