import React from "react";
import { TableCell, Typography } from "@material-ui/core";
import styles from "../../styles";
const Cell = ({ content }) => {
  const classes = styles();
  return (
    <TableCell className={classes.tableCell}>
      <Typography variant="caption">{content}</Typography>
    </TableCell>
  );
};
export default Cell;
