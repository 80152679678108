import { makeStyles } from "@material-ui/core";
const styles = makeStyles((theme) => ({
  popover: {
    zIndex: 1001,
    position: "absolute",
    width: "100%",
    height: "max-content",
    background: theme.palette.primary.main,
    textAlign: "center",
    padding: "0 0.5rem",
  },
  top: {
    top: 0,
  },
  bottom: {
    bottom: 0,
  },

  active: {
    cursor: "pointer",
  },
}));
export default styles;
