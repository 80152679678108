import React, { useState, useEffect } from "react";
import styles from "./styles";
import { Grid, Box } from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import Column from "src/components/Column";

import { OrdersHistory, Row, AccountForm } from "./elements";

import { getUser, setUser } from "../../../utils/auth";
import { Link } from "gatsby";

export const Account = () => {
  const [isEditable, setIsEditable] = useState(false);
  const classes = styles();

  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    setUserInfo(getUser());
    console.log(getUser());
  }, [isEditable, setIsEditable]);
  return (
    <>
      <h1 className={classes.h1}>ACCOUNT INFORMATION</h1>
      {!isEditable && (
        <EditIcon
          onClick={() => setIsEditable(!isEditable)}
          className={classes.icon}
        />
      )}
      {isEditable ? (
        <AccountForm
          userInfo={userInfo}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          setUser={setUser}
          setUserInfo={setUserInfo}
        />
      ) : (
        <Grid
          container
          direction="row"
          wrap="wrap"
          justify="space-between"
          spacing={2}
        >
          <Column title="Contact">
            <Row userInfo={userInfo} name="name" />
            <Row userInfo={userInfo} name="surname" />
            <Row userInfo={userInfo} name="email" />
            <Row userInfo={userInfo} name="phone" />
          </Column>

          <Column title="Store Info">
            <Row userInfo={userInfo} name="store" />
            <Row userInfo={userInfo} name="website" />
            <Row userInfo={userInfo} name="department" />
          </Column>

          <Column title="Billing Details">
            <Row name="custom:billingCompanyName" userInfo={userInfo} />
            <Row name="custom:billingAddress" userInfo={userInfo} />
            <Row name="custom:billingCity" userInfo={userInfo} />
            <Row name="custom:billingZipCode" userInfo={userInfo} />
            <Row name="custom:billingCountry" userInfo={userInfo} />
            <Row name="custom:billingContact" userInfo={userInfo} />
            <Row name="custom:billingEmail" userInfo={userInfo} />
            <Row name="custom:billingPhone" userInfo={userInfo} />
            <Row name="custom:billingTaxID" userInfo={userInfo} />
          </Column>

          <Column title="Delivery Details">
            <Row name="custom:companyName" userInfo={userInfo} />
            <Row name="custom:address" userInfo={userInfo} />
            <Row name="custom:city" userInfo={userInfo} />
            <Row name="custom:zipCode" userInfo={userInfo} />
            <Row name="custom:country" userInfo={userInfo} />
            <Row name="custom:contact" userInfo={userInfo} />
            <Row name="custom:storeEmail" userInfo={userInfo} />
            <Row name="custom:storePhone" userInfo={userInfo} />
            <Row name="custom:taxID" userInfo={userInfo} />
          </Column>
        </Grid>
      )}
      <Box mt={2}>
        <Link to="/change-password">Change password</Link>
      </Box>
      <OrdersHistory />
    </>
  );
};
export default Account;
