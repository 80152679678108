import { makeStyles } from "@material-ui/core";
const styles = makeStyles((theme) => ({
  h2: {
    fontSize: 14,
    margin: "0",
    whiteSpace: "nowrap",
    fontWeight: "bold",
  },
}));
export default styles;
