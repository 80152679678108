import { makeStyles } from "@material-ui/core";
const styles = makeStyles((theme) => ({
  h1: {
    fontSize: 24,
    margin: "3.125rem 1.25rem 2.625rem 0",
    display: "block",
  },
  confirm: {
    width: "8.125rem",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginTop: "1rem",
      width: "14.625rem",
    },

    [theme.breakpoints.up("md")]: {
      marginLeft: "5rem",
      marginRight: "1.5rem",
      display: "inline",
    },
  },
  buttons: {
    marginBottom: "5.875rem",
  },
}));
export default styles;
