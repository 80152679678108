import React, { useContext } from "react";
import PropTypes from "prop-types";
import styles from "./styles";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import AdminTable from "./AdminTable";
import { Context } from "src/components/Context";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const AdminTabs = ({ requests, fetchRequests }) => {
  const classes = styles();
  const [value, setValue] = React.useState(0);
  const { orders, checkout } = useContext(Context);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label="Requests" {...a11yProps(0)} />
        <Tab label="Orders" {...a11yProps(1)} />
        <Tab label="Checkouts" {...a11yProps(2)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <AdminTable
          fetchRequests={fetchRequests}
          content={requests}
          tabNumber={0}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AdminTable content={orders} tabNumber={1} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AdminTable content={checkout} tabNumber={2} />
      </TabPanel>
    </div>
  );
};

export default AdminTabs;
