import { makeStyles } from "@material-ui/core";
const styles = makeStyles((theme) => ({
  table: { marginBottom: "3.125rem" },
  select: { marginLeft: "auto" },
  image: {
    height: "5.125rem",
    width: "4rem",
  },
  h2: {
    fontSize: 14,
    margin: "0",
    whiteSpace: "nowrap",
    fontWeight: "bold",
  },
  button: {
    padding: 0,
  },
}));
export default styles;
