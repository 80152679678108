import React, { useContext, useCallback, useEffect } from "react";
import { Typography, TableCell, TableRow, IconButton } from "@material-ui/core";
import { Context } from "src/components/Context";
import styles from "./styles";

import { useFormik } from "formik";
import { API, graphqlOperation } from "aws-amplify";
import { createProduct, deleteSelection } from "src/graphql/myMutations";

import { countPrice } from "src/utils/helpers";
import { useSnackbar } from "notistack";
import AddIcon from "@material-ui/icons/Add";
import isEmpty from "lodash/isEmpty";
import Img from "gatsby-image";
import Analytics from "@aws-amplify/analytics";

export const Row = ({ selection = {} }) => {
  const { skuData, fetchCheckouts, checkout, fetchSelections } = useContext(
    Context
  );

  const { enqueueSnackbar } = useSnackbar();

  const SKU = selection.SKU;
  const selectionId = selection.id;

  const productInAirtable = skuData.find(({ data }) => data.SKU === SKU);

  const product = productInAirtable.data;

  const WHP = product.Wholesale_Price;
  const name = product.Name;
  const colors = product.Colors;
  const defaultColor = colors[0];
  const RRP = product.RRP;

  const checkoutID = checkout.id;
  const Available_sizes = product.Available_sizes;
  const photo = product.SKU_Photos
    ? product.SKU_Photos.localFiles[0].childImageSharp.fluid
    : "";
  const brand = product.BrandName[0];

  const classes = styles();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      items: [],
      SKU: SKU,
      price: 0,
      WHP: parseInt(WHP),
      name: name,
      checkoutId: checkoutID,
      brand: brand,
      color: defaultColor,
      RRP: parseInt(RRP),
    },
    onSubmit: async (values) => {
      values.price = countPrice(values.items, values.WHP);
      const jsonItems = JSON.stringify(values.items);
      values.items = jsonItems;
      console.log(values);
      try {
        await API.graphql(
          graphqlOperation(createProduct, {
            input: values,
          })
        );
        enqueueSnackbar("Items have been added");
        Analytics.record("add-to-cart");
      } catch (err) {
        console.log(err);
      }

      try {
        await API.graphql(
          graphqlOperation(deleteSelection, {
            input: { id: selectionId },
          })
        );
      } catch (err) {
        console.log(err);
      }
      mapSizesToState();
      fetchCheckouts();
      fetchSelections();
    },
  });

  const mapSizesToState = useCallback(() => {
    const updatedItems = [];
    Available_sizes.forEach((size, index) => {
      updatedItems.push({ [size]: "" });
    });

    formik.setValues({ ...formik.values, items: updatedItems });
  }, [Available_sizes, formik]);

  useEffect(() => {
    mapSizesToState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TableRow className={classes.row}>
      <TableCell>
        <Typography variant="caption">{SKU}</Typography>
      </TableCell>
      <TableCell>
        <Img className={classes.image} fluid={photo} alt="clothes" />
      </TableCell>
      <TableCell>
        <Typography variant="caption">{name}</Typography>
      </TableCell>

      <TableCell>{WHP} €</TableCell>
      <TableCell>
        <IconButton
          color="primary"
          onClick={formik.handleSubmit}
          disabled={isEmpty(checkout)}
          className={classes.button}
        >
          <AddIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
export default Row;
