import { TableHeader } from "./elements";
import ProductRow from "src/components/ProductRow";
import React, { useContext, useEffect, useMemo } from "react";
import styles from "./styles";
import { Context } from "../Context";
import {
  Table,
  TableBody,
  TableContainer,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
} from "@material-ui/core";
import { countQuantity } from "src/utils/helpers";

const ProductsTable = ({
  selectedBrand = "",
  setSelectedBrand,
  filteredProducts = [],
  selectOptions = new Set(),
  isCheckout = Boolean,
  deliveryTerms,
}) => {
  const classes = styles();
  const { fetchCheckouts } = useContext(Context);

  const mappedProducts = filteredProducts.map((product, index) => (
    <ProductRow
      key={product.id}
      product={product}
      isCheckout={isCheckout}
      index={index}
    />
  ));

  const price = filteredProducts.reduce((acc, item) => acc + item.price, 0);

  const parsedItems = filteredProducts.map((item) => JSON.parse(item.items));

  const quantity = countQuantity(parsedItems);

  const selectOptionsArr = [...selectOptions];

  useEffect(() => {
    fetchCheckouts();
  }, [fetchCheckouts]);
  const radioButtons = useMemo(
    () =>
      selectOptionsArr.map((brand) => (
        <FormControlLabel
          key={brand}
          value={brand}
          control={<Radio color="primary" />}
          label={brand}
          labelPlacement="end"
        />
      )),
    [selectOptionsArr]
  );

  const newLinedTerms =
    deliveryTerms &&
    deliveryTerms.split("\n").map((item, key) => {
      return (
        <Typography display="block" variant="caption" key={key}>
          {item}
        </Typography>
      );
    });
  return (
    <>
      {isCheckout && (
        <>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="brands"
              name="brands"
              onChange={(e) => setSelectedBrand(e.target.value)}
              value={selectedBrand || ""}
            >
              {radioButtons}
            </RadioGroup>
          </FormControl>
          {deliveryTerms && <Typography>{newLinedTerms}</Typography>}
        </>
      )}
      <TableContainer className={classes.table}>
        <Table aria-label="products table">
          <TableHeader isCheckout={isCheckout} />
          <TableBody>{mappedProducts}</TableBody>
        </Table>
      </TableContainer>
      <h1 className={classes.h1}>TOTAL: €{price}</h1>
      <h1 className={classes.h1}>TOTAL QUANTITY: {quantity}</h1>
    </>
  );
};
export default ProductsTable;
