import { TableHeader } from "./TableHeader";
import Row from "./Row";
import React, { useContext, useEffect } from "react";
import styles from "./styles";
import { Context } from "src/components/Context";
import { Table, TableBody, TableContainer } from "@material-ui/core";

const SelectionTable = () => {
  const classes = styles();
  const { fetchSelections, selections } = useContext(Context);

  const mappedProducts = selections.map((selection, index) => (
    <Row key={selection.id} selection={selection} index={index} />
  ));

  useEffect(() => {
    fetchSelections();
  }, [fetchSelections]);

  return (
    <>
      <TableContainer className={classes.table}>
        <Table aria-label="products table">
          <TableHeader />
          <TableBody>{mappedProducts}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default SelectionTable;
