import React, { useMemo } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import isEmpty from "lodash/isEmpty";
import Row from "./Row";
import styles from "./styles";
import { Typography } from "@material-ui/core";

export const AdminTable = ({ content = [], tabNumber, fetchRequests }) => {
  const classes = styles();

  const headers = useMemo(
    () =>
      !isEmpty(content) &&
      Object.keys(content[0]).map((header) => (
        <TableCell key={header}>
          <Typography>{header}</Typography>
        </TableCell>
      )),
    [content]
  );
  const rows = useMemo(
    () =>
      !isEmpty(content) &&
      content.map((row) => (
        <Row
          fetchRequests={fetchRequests}
          key={row.id}
          row={row}
          tabNumber={tabNumber}
        />
      )),

    [content, tabNumber, fetchRequests]
  );

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tabNumber !== 0 && <TableCell></TableCell>}
            {headers}
            {tabNumber === 0 && (
              <>
                <TableCell>
                  <Typography>Cancel</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Approve</Typography>
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdminTable;
