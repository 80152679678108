import React, { useContext, useState, useEffect, useCallback } from "react";

import ProductsTable from "../../ProductsTable";
import { Button, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "./styles";
import { API, graphqlOperation } from "aws-amplify";
import { createOrder, deleteCheckout } from "src/graphql/mutations";
import { createOrderProduct } from "src/graphql/myMutations";
import { Context } from "../../Context";
import { getUser } from "src/utils/auth";
import isEmpty from "lodash/isEmpty";
import { nanoid } from "nanoid";
import { navigate } from "gatsby";
import { updateProduct } from "src/graphql/myMutations";

import { useSnackbar } from "notistack";
import SelectionTable from "./SelectionTable";
import { countQuantity } from "../../../utils/helpers";
import Analytics from "@aws-amplify/analytics";

export const Checkout = ({ unconfirmedOrderBrand }) => {
  const {
    setProducts,
    products,
    checkoutBrands,
    checkout,
    brandsData,
    selections,
    setCheckout,
  } = useContext(Context);
  const checkoutBrandsArr = [...checkoutBrands];

  const [selectedBrand, setSelectedBrand] = useState(
    unconfirmedOrderBrand || checkoutBrandsArr[0]
  );

  const [filteredProducts, setFilteredProducts] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [orderId, setOrderId] = useState(nanoid);
  const classes = styles();
  const email = getUser().email;

  const checkoutDelete = useCallback(async () => {
    try {
      await API.graphql(
        graphqlOperation(deleteCheckout, {
          input: {
            id: checkout.id,
          },
        })
      );
      setCheckout({});
    } catch (err) {
      console.log(err);
    }
  }, [checkout, setCheckout]);

  const filterByBrand = useCallback(() => {
    const filteredByBrand = products.filter(
      (product) => product.brand === selectedBrand
    );
    const newProducts = isEmpty(filteredByBrand) ? products : filteredByBrand;
    setFilteredProducts(newProducts);
  }, [products, selectedBrand]);

  useEffect(() => {
    filterByBrand();
  }, [products, selectedBrand, filterByBrand]);

  useEffect(() => {
    Analytics.record("checkout-visit");
  }, []);

  const handleConfirm = async () => {
    try {
      await API.graphql(
        graphqlOperation(createOrder, {
          input: {
            email: email,
            status: "Confirmed",
            brand: selectedBrand,
            id: orderId,
            deliveryTerms: deliveryTerms,
            totalPrice: price,
            totalQuantity: quantity,
          },
        })
      );

      enqueueSnackbar("Order has been created");
      Analytics.record("confirm-order");
      filteredProducts.forEach(async (product) => {
        const productId = product.id;
        const orderProductId = nanoid();
        console.log(orderProductId);
        /* remove products from checkout */
        await API.graphql(
          graphqlOperation(createOrderProduct, {
            input: {
              id: orderProductId,
              orderId: orderId,
              productId: productId,
            },
          })
        );

        await API.graphql(
          graphqlOperation(updateProduct, {
            input: {
              id: productId,
              checkoutId: null,
            },
          })
        );
      });
    } catch (err) {
      console.log(err);
    }

    const filteredByBrand = products.filter(
      (product) => product.brand !== selectedBrand
    );

    isEmpty(filteredByBrand) && checkoutDelete();
    setProducts(filteredByBrand);

    const currentIndex = checkoutBrandsArr.indexOf(selectedBrand);

    const nextBrandIndex = currentIndex === 0 ? 1 : currentIndex - 1;

    setSelectedBrand(checkoutBrandsArr[nextBrandIndex]);

    unconfirmedOrderBrand && navigate("/checkout");
    console.log(orderId);
    setOrderId(nanoid());
  };

  const brandInAirtable = brandsData.find(
    ({ data }) => data.Name === selectedBrand
  );

  const { MOQ, Delivery_Dates, Delivery_Terms, Payment_Terms } = brandInAirtable
    ? brandInAirtable.data
    : {};

  const deliveryTerms =
    brandInAirtable &&
    `
    MOQ:${MOQ}\n
    Delivery Dates:${Delivery_Dates}\n
    Delivery Terms:${Delivery_Terms}\n
    Payment Terms:${Payment_Terms}\n

  `;
  const price = filteredProducts.reduce((acc, item) => acc + item.price, 0);

  const parsedItems = filteredProducts.map((item) => JSON.parse(item.items));

  const quantity = countQuantity(parsedItems);

  const showSelection = !isEmpty(selections);
  return (
    <>
      <h1 className={classes.h1}>ORDER OVERVIEW</h1>
      <ProductsTable
        selectedBrand={selectedBrand}
        setSelectedBrand={setSelectedBrand}
        filteredProducts={filteredProducts}
        setFilteredProducts={setFilteredProducts}
        selectOptions={checkoutBrands}
        isCheckout={true}
        deliveryTerms={deliveryTerms}
        quantity={quantity}
        price={price}
      />
      <div className={classes.buttons}>
        <Button endIcon={<ExpandMoreIcon />}>SAVE AN ORDER</Button>
        <Button
          disabled={!selectedBrand}
          className={classes.confirm}
          onClick={handleConfirm}
        >
          CONFIRM
        </Button>
        <Typography variant="caption">
          We will automatically receive an order and send you an OC
        </Typography>
      </div>
      {showSelection && (
        <>
          <h1 className={classes.h1}>YOUR SELECTION</h1>
          <SelectionTable />
        </>
      )}
    </>
  );
};
export default Checkout;
