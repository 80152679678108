import React, { useContext, useMemo, useCallback } from "react";
import styles from "./styles";
import { Typography, Box, Hidden } from "@material-ui/core";
import { navigate } from "gatsby";
import { API, graphqlOperation } from "aws-amplify";
import { createSelection, deleteSelection } from "src/graphql/mutations";
import { Context } from "../../../Context";
import { useSnackbar } from "notistack";
import Analytics from "@aws-amplify/analytics";

const Popover = ({ SKUS, brandName }) => {
  const classes = styles();
  const { fetchSelections, selections } = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();

  const findSKUdata = useCallback(
    (indexOfSKU) => {
      let skuData;
      if (SKUS[indexOfSKU]) {
        skuData = SKUS[indexOfSKU].data;
      } else {
        skuData = SKUS[0].data;
      }
      return skuData;
    },
    [SKUS]
  );

  const isSelected = useCallback(
    (skuData) => selections.some((selection) => selection.SKU === skuData.SKU),
    [selections]
  );

  const SKUinSelections = useCallback(
    (skuData) => selections.find((selection) => selection.SKU === skuData.SKU),
    [selections]
  );

  const handleClick = useCallback(
    async (indexOfSKU) => {
      const skuData = findSKUdata(indexOfSKU);

      if (!isSelected(skuData)) {
        try {
          await API.graphql(
            graphqlOperation(createSelection, {
              input: {
                SKU: skuData.SKU,
                name: skuData.Name,
                brand: brandName,
              },
            })
          );
          enqueueSnackbar("Added to selection");
          Analytics.record("add-to-selection");
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          const id = SKUinSelections(skuData).id;

          await API.graphql(
            graphqlOperation(deleteSelection, {
              input: { id: id },
            })
          );
          enqueueSnackbar("Removed from selection");
        } catch (err) {
          console.log(err);
        }
      }

      fetchSelections();
    },
    [
      SKUinSelections,
      brandName,
      enqueueSnackbar,
      fetchSelections,
      findSKUdata,
      isSelected,
    ]
  );

  const popoverClass = useCallback(
    (index) => {
      if (SKUS.length > 1) {
        return `${classes.popover} ${
          index === 0 ? classes.top : classes.bottom
        }`;
      } else return `${classes.popover} ${classes.bottom}`;
    },
    [SKUS.length, classes.bottom, classes.top, classes.popover]
  );

  const mappedSKUS = useMemo(
    () =>
      SKUS.map(({ data }, index) => (
        <Box key={data.Title} className={popoverClass(index)}>
          <Box display="inline" position="absolute" left={8}>
            <Typography color="textSecondary" variant="caption">
              {data.Category || ""}
            </Typography>
          </Box>
          <Box display="inline" ml="auto">
            <Typography
              className={classes.active}
              color="textSecondary"
              onClick={() => navigate(`/sku/${data.SKU}`)}
              variant="caption"
            >
              {data.Name}
            </Typography>
          </Box>
          <Box display="inline" position="absolute" right={8}>
            <Typography
              className={classes.active}
              onClick={() => handleClick(index)}
              variant="caption"
              color="textSecondary"
            >
              {isSelected(findSKUdata(index)) ? "- Remove" : "+ Add"}
            </Typography>
          </Box>
        </Box>
      )),
    [SKUS, classes.active, findSKUdata, handleClick, isSelected, popoverClass]
  );

  const mobileSKUS = useMemo(
    () =>
      SKUS.map(({ data }, index) => (
        <Box position="relative" key={data.SKU}>
          <Box display="inline" position="absolute" left={8}>
            <Typography variant="caption">{data.Category || ""}</Typography>
          </Box>
          <Box
            display="inline-flex"
            ml="4.375rem"
            width="210px"
            justifyContent="center"
          >
            <Typography
              className={classes.active}
              onClick={() => navigate(`/sku/${data.SKU}`)}
              variant="caption"
            >
              {data.Name}
            </Typography>
          </Box>
          <Box display="inline" position="absolute" right={8}>
            <Typography
              className={classes.active}
              onClick={() => handleClick(index)}
              variant="caption"
            >
              {isSelected(findSKUdata(index)) ? "- Remove" : "+ Add"}
            </Typography>
          </Box>
        </Box>
      )),
    [SKUS, classes.active, findSKUdata, isSelected, handleClick]
  );

  return (
    <>
      <Hidden xsDown>{mappedSKUS} </Hidden>
      <Hidden smUp>{mobileSKUS}</Hidden>
    </>
  );
};
export default Popover;
