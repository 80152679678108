import React, { useEffect, useState, useCallback } from "react";

import { listRequests } from "src/graphql/queries";
import { API, graphqlOperation } from "aws-amplify";

import { AdminTabs } from "./elements";

export const Admin = () => {
  const [requests, setRequests] = useState([]);

  const fetchRequests = useCallback(async () => {
    try {
      const ItemsData = await API.graphql(graphqlOperation(listRequests));

      const requestsList = ItemsData.data.listRequests.items;

      setRequests(requestsList);

      console.log("requests", requestsList);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  return <AdminTabs requests={requests} fetchRequests={fetchRequests} />;
};

export default Admin;
