import React from "react";
import styles from "./styles";
import { Grid, Box, Hidden } from "@material-ui/core";
import Popover from "./Popover";
import Img from "gatsby-image";

import useHover from "react-use-hover";
const Card = ({ photo, brandName, SKUS, name }) => {
  const classes = styles();

  const mouseEnterDelayMS = 0;
  const mouseLeaveDelayMS = 0;
  const [isHovering, hoverProps] = useHover({
    mouseEnterDelayMS,
    mouseLeaveDelayMS,
  });

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box {...hoverProps} position="relative" width="100%" height="100%">
        <Img className={classes.media} fluid={photo} alt="product" />

        {isHovering && (
          <Hidden xsDown>
            {" "}
            <Popover SKUS={SKUS} brandName={brandName} name={name} />
          </Hidden>
        )}
      </Box>
      <Hidden smUp>
        <Popover SKUS={SKUS} brandName={brandName} name={name} />
      </Hidden>
    </Grid>
  );
};
export default Card;
