import { makeStyles } from "@material-ui/core";
const styles = makeStyles((theme) => ({
  h2: {
    fontSize: 14,
    margin: "0",
    whiteSpace: "nowrap",
  },
  icon: {
    verticalAlign: "middle",
    paddingBottom: "3px",
  },
  image: {
    height: "5.125rem",
    width: "4rem",
  },

  input: {
    width: "1.5rem",
    height: "0.9375rem",
    fontSize: 12,
    display: "block",
    marginBottom: "5px",
  },
  inputProps: {
    padding: "1px 10px 0px 4px",
  },
  row: {
    border: 0,
    width: "100%",
  },
  select: {
    fontSize: 12,
  },
}));
export default styles;
