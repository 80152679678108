import React, { useMemo, useState, useCallback, useContext } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { Typography } from "@material-ui/core";
import { updateRequest, addUserPython } from "src/graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";

import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ProductRow from "src/components/ProductRow";
import pick from "lodash/pick";
import { Context } from "components/Context";

export const Row = ({ row = {}, tabNumber, fetchRequests }) => {
  const id = row.id;
  const [isExpanded, setIsExpanded] = useState(false);
  const [items, setItems] = useState([]);

  const { getProductsByOrderId } = useContext(Context);

  const toggleExpanded = useCallback(() => {
    if (!isExpanded) {
      getProductsByOrderId(id, setItems);
    }
    setIsExpanded(!isExpanded);
  }, [getProductsByOrderId, id, isExpanded]);

  const updateRequestStatus = useCallback(
    async (status) => {
      try {
        await API.graphql(
          graphqlOperation(updateRequest, {
            input: { id: id, status: status },
          })
        );
        fetchRequests();
      } catch (err) {
        console.log(err);
      }
    },
    [fetchRequests, id]
  );

  const createUser = useCallback(async () => {
    const inputValue = pick(row, [
      "email",
      "name",
      "surname",
      "phone",
      "store",
      "website",
      "department",
    ]);
    try {
      await API.graphql(
        graphqlOperation(addUserPython, {
          input: inputValue,
        })
      );
      fetchRequests();
    } catch (err) {
      console.log(err);
    }
  }, [fetchRequests, row]);

  const handleApprove = useCallback(() => {
    createUser();
    updateRequestStatus("approved");
  }, [createUser, updateRequestStatus]);

  const handleCancel = useCallback(() => {
    updateRequestStatus("canceled");
  }, [updateRequestStatus]);

  console.log(row);
  const expandRows = useMemo(
    () =>
      items.map((product) => (
        <ProductRow
          key={product.id}
          isCheckout={false}
          product={product}
        ></ProductRow>
      )),
    [items]
  );

  const rowTemplate = useMemo(
    () => (
      <>
        <TableRow key={row.id}>
          {tabNumber !== 0 && (
            <TableCell>
              <IconButton onClick={toggleExpanded}>
                {isExpanded ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
          )}
          {Object.values(row).map((value, index) => {
            return (
              <TableCell key={`${value} ${index}`}>
                <Typography variant="caption">
                  {typeof value !== "object" && value}
                </Typography>
              </TableCell>
            );
          })}
          {tabNumber === 0 && (
            <>
              <TableCell>
                <IconButton onClick={handleCancel}>
                  <CancelIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton onClick={handleApprove}>
                  <CheckCircleIcon />
                </IconButton>
              </TableCell>
            </>
          )}
        </TableRow>
        {isExpanded && expandRows}
      </>
    ),
    [
      row,
      tabNumber,
      expandRows,
      isExpanded,
      handleApprove,
      handleCancel,
      toggleExpanded,
    ]
  );

  return rowTemplate;
};

export default Row;
